//接口调用次数情况
const getInterfaceTimesUrl = `/gateway/hc-portal/openInfoSts/stsInfo`;
//接口调用日统计
const getInterfaceDayUrl = `/gateway/hc-portal/openInfoSts/stsListByDay`;
//接口调用分类统计
const getInterfaceKindsUrl = `/gateway/hc-portal/openInfoSts/stsListByOpenId`;
//接口调用统计
const getInterfaceUrl = `/gateway/hc-portal/openInfoSts/stsPageList`;
export {
  getInterfaceTimesUrl,
  getInterfaceDayUrl,
  getInterfaceKindsUrl,
  getInterfaceUrl,
};
