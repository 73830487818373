<template>
  <div class="runCondition">
    <el-row :gutter="20">
      <el-col :span="12"
        ><div class="getInterfaceTimes">
          <div class="img">
            <img src="./img/interfaceTimesImg.png" alt="" />
          </div>
          <div class="getInterfaceTimes-l">
            <div class="box">
              <div class="num">{{ interfaceTimesData.totalStsCount }}</div>
              <div class="txt">资源接口总调用次数</div>
            </div>
            <div class="box">
              <div class="num">
                {{ interfaceTimesData.totalStsCountToday }}
              </div>
              <div class="txt">今日调用次数</div>
            </div>
          </div>
          <div class="getInterfaceTimes-r">
            <div class="circle te">
              <div class="num">
                <countTo
                  :startVal="0"
                  :endVal="
                    interfaceTimesData.totalOpenInfos
                      ? interfaceTimesData.totalOpenInfos
                      : 0
                  "
                  :duration="3000"
                ></countTo>
              </div>
              <div class="txt">资源开放总数</div>
            </div>
          </div>
        </div></el-col
      >
      <el-col :span="12"
        ><div class="getInterfaceTimes">
          <div class="img">
            <img src="./img/interfaceTimesImg2.png" alt="" />
          </div>
          <div class="getInterfaceTimes-l">
            <div class="box">
              <div class="num">{{ interfaceTimesData.totalDataMaskCount }}</div>
              <div class="txt">脱敏总数量</div>
            </div>
            <div class="box">
              <div class="num">
                {{ interfaceTimesData.totalDataMaskCountToday }}
              </div>
              <div class="txt">今日调用次数</div>
            </div>
          </div>
          <div class="getInterfaceTimes-r">
            <div class="circle">
              <div class="num">
                <countTo
                  :startVal="0"
                  :endVal="
                    interfaceTimesData.totalDataMaskInfos
                      ? interfaceTimesData.totalDataMaskInfos
                      : 0
                  "
                  :duration="3000"
                ></countTo>
              </div>
              <div class="txt">风控规则总数</div>
            </div>
          </div>
        </div></el-col
      >
    </el-row>
    <el-row :gutter="20">
      <el-col :span="18"
        ><div class="interfaceDay">
          <div class="interfaceDay-t">
            <div class="title">资源每日调用次数曲线图</div>
            <div class="time">
              <v-date-picker
                v-model="createTime"
                type="daterange"
                formatValue="yyyy-MM-dd"
                format="yyyy-MM-dd"
                startPlaceholder="开始时间"
                endPlaceholder="结束时间"
                @change="changeDate"
              />
            </div>
          </div>
          <div class="interfaceDay-b"><div id="Dau"></div></div></div
      ></el-col>
      <el-col :span="6"
        ><div class="resourceRank">
          <div class="title">资源调用次数排名Top10</div>
          <div class="resourceRank-body">
            <div
              class="item"
              v-for="(item, index) in interfaceKinds"
              :key="index"
            >
              <div
                :class="[
                  'item-l',
                  { te1: index == 0, te2: index == 1, te3: index == 2 },
                ]"
              >
                <div class="num">{{ index > 2 ? index + 1 : "" }}</div>
                <div class="txt">{{ item.openUrlName }}</div>
              </div>
              <div class="item-r">{{ item.totalStsCount }}</div>
            </div>
          </div>
        </div></el-col
      >
    </el-row>
    <el-row :gutter="20">
      <el-col :span="12"
        ><div class="interfaceArea">
          <div class="title">资源调用每日次数接口分布图</div>
          <div id="interfacePie"></div></div
      ></el-col>
      <el-col :span="12"
        ><div class="interfaceTotal">
          <div class="title">各资源调用总图</div>
          <div id="interfaceLine"></div></div
      ></el-col>
    </el-row>
  </div>
</template>

<script>
import {
  drawLineChart,
  drawDLineChart,
  drawPieChart,
  drawColumnChart,
} from "@/utils/echart";
import { debounce } from "@/utils/utils";
import {
  getInterfaceTimesUrl,
  getInterfaceDayUrl,
  getInterfaceKindsUrl,
  getInterfaceUrl,
} from "./api.js";
import countTo from "vue-count-to";
import moment from "moment";
export default {
  name: "runCondition",
  data() {
    return {
      DauData: {
        x: [],
        y: [],
        pieData: [],
      },
      columnData: {
        x: [],
        y: [],
      },
      createTime: [
        new Date(moment(new Date()).subtract(15, "days").format("YYYY-MM-DD")),
        new Date(),
      ],
      interfaceKinds: [],
      interfaceTimesData: {},
      interfaceDayData: {},
      judgeAjax: "",
      judgePie: "",
      judgeColumn: "",
    };
  },
  components: {
    countTo,
  },
  async mounted() {
    let _this = this;
    let resKind = await this.getInterfaceTotalKinds();
    if (resKind.code === 200) {
      resKind.data.forEach((ele) => {
        this.columnData.x.push(ele.openUrlName);
        this.columnData.y.push(ele.totalStsCount);
      });
      drawColumnChart("interfaceLine", this.columnData.x, this.columnData.y);
      this.judgeColumn = debounce(() => {
        drawColumnChart(
          "interfaceLine",
          this.columnData.x,
          this.columnData.y
        ).resize();
      }, 100);
    }
    let resKind1 = await this.getInterfaceKinds();
    if (resKind1.code === 200) {
      this.interfaceKinds = resKind1.data;
      resKind1.data.forEach((ele) => {
        let obj = {};
        obj.value = ele.totalStsCount;
        obj.name = ele.openUrlName;
        this.DauData.pieData.unshift(obj);
      });
      drawPieChart("interfacePie", this.DauData.x, this.DauData.pieData);
      this.judgePie = debounce(() => {
        drawPieChart(
          "interfacePie",
          this.DauData.x,
          this.DauData.pieData
        ).resize();
      }, 100);
    }

    this.getInterfaceTimes();
    let time1 = moment(new Date(this.createTime[0])).format("YYYY-MM-DD");
    let time2 = moment(new Date(this.createTime[1])).format("YYYY-MM-DD");
    let res = await this.getInterfaceDay(time1, time2);
    if (res.code == 200) {
      res.data.forEach((ele) => {
        this.DauData.x.unshift(ele.stsDay);
        this.DauData.y.unshift(ele.totalStsCount);
      });
      drawLineChart("Dau", this.DauData.x, this.DauData.y);
      this.judgeAjax = debounce(() => {
        drawLineChart("Dau", this.DauData.x, this.DauData.y).resize();
      }, 100);
    }
    window.addEventListener("resize", _this.judgeAjax, true);
    window.addEventListener("resize", _this.judgePie, true);
    window.addEventListener("resize", _this.judgeColumn, true);
  },
  destroyed() {
    let _this = this;
    window.removeEventListener("resize", _this.judgeAjax);
    window.removeEventListener("resize", _this.judgePie);
    window.removeEventListener("resize", _this.judgeColumn);
  },
  methods: {
    async getInterfaceTotalKinds() {
      let params = {};
      let res = await this.$axios.get(`${getInterfaceKindsUrl}`, { params });
      return res;
    },
    async getInterface() {
      let params = {};
      let res = await this.$axios.get(`${getInterfaceUrl}`, { params });
      return res;
    },
    async getInterfaceKinds(stsDay) {
      let params = {
        pageSize: 10,
        stsDay: stsDay,
      };
      let res = await this.$axios.get(`${getInterfaceKindsUrl}`, { params });
      return res;
    },
    async changeDate() {
      this.DauData.x = [];
      this.DauData.y = [];
      let res = await this.getInterfaceDay(
        this.createTime[0],
        this.createTime[1]
      );
      if (res.code == 200) {
        res.data.forEach((ele) => {
          this.DauData.x.unshift(ele.stsDay);
          this.DauData.y.unshift(ele.totalStsCount);
        });
        this.judgeAjax();
      }
    },
    async getInterfaceDay(startTime, endTime) {
      let _this = this;
      let params = {
        startTime: startTime,
        endTime: endTime,
      };
      let res = await this.$axios.get(`${getInterfaceDayUrl}`, {
        params: params,
      });
      return res;
    },
    async getInterfaceTimes() {
      let params = {};
      let res = await this.$axios.get(`${getInterfaceTimesUrl}`);
      if (res.code === 200) {
        this.interfaceTimesData = res.data;
        for (let i in this.interfaceTimesData) {
          if (!this.interfaceTimesData[i]) {
            this.interfaceTimesData[i] = 0;
          }
          this.interfaceTimesData[i] = Number(this.interfaceTimesData[i]);
        }
      }
    },
    batchSend(id) {},

    deleteWord(ids) {},
    toAdd() {
      this.$router.push({
        name: "addSensitiveWords",
      });
    },
    toEdit(data) {
      this.$router.push({
        name: "accountAdd",
        query: { id: data.id, communityId: data.communityId },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.runCondition {
  box-sizing: border-box;
  height: 100%;
  padding-top: 60px;
  overflow-x: hidden;
  /*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #f5f5f5;
  }

  /*定义滚动条轨道 内阴影+圆角*/
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    background-color: #f5f5f5;
  }

  /*定义滑块 内阴影+圆角*/
  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #f5f5f5;
  }
  overflow-y: auto;
  .title {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 20px;
  }
  .getInterfaceTimes {
    width: 100%;
    height: 280px;
    background: #ffff;
    box-shadow: 0px 0px 32px 0px rgba(66, 114, 153, 0.1);
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 20px;
    box-sizing: border-box;
    position: relative;
    margin: 0 0 20px 20px;
    .img {
      width: 40px;
      height: 40px;
      position: absolute;
      top: 10px;
      left: 10px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .getInterfaceTimes-l {
      .box {
        margin-bottom: 10px;
        .num {
          font-size: 48px;
          font-weight: 700;
        }
        .txt {
          font-size: 20px;
          color: #666;
        }
      }
    }
    .getInterfaceTimes-r {
      width: 200px;
      height: 200px;
      border: 8px solid rgba(0, 0, 0, 0.1);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      .circle {
        .num {
          font-size: 80px;
          font-weight: 700;
          color: rgba(54, 184, 119, 1);
        }
        .txt {
          font-size: 20px;
          color: #666;
        }
      }
      .te {
        .num {
          color: rgba(131, 128, 255, 1);
        }
      }
      .num,
      .txt {
        text-align: center;
      }
    }
  }
  .interfaceDay {
    height: 560px;
    background: #ffff;
    box-shadow: 0px 0px 32px 0px rgba(66, 114, 153, 0.1);
    padding: 20px;
    box-sizing: border-box;
    margin: 0 0 20px 20px;
    .interfaceDay-t {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .interfaceDay-b {
      height: 480px;
      #Dau {
        height: 100%;
      }
    }
  }
  .resourceRank {
    height: 560px;
    background: #ffff;
    box-shadow: 0px 0px 32px 0px rgba(66, 114, 153, 0.1);
    padding: 20px;
    box-sizing: border-box;
    margin: 0 0 20px 20px;
    .resourceRank-body {
      height: 460px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }

    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .item-l {
        display: flex;
        align-items: center;
        .num {
          text-align: center;
          flex-shrink: 0;
          color: rgb(248, 196, 9);
          width: 30px;
          height: 30px;
          margin-right: 20px;
          line-height: 30px;
        }
      }
      .te1 {
        .num {
          background: url("./img/rank1.png") no-repeat;
          background-size: 100% 100%;
        }
      }
      .te2 {
        .num {
          background: url("./img/rank2.png") no-repeat;
          background-size: 100% 100%;
        }
      }
      .te3 {
        .num {
          background: url("./img/rank3.png") no-repeat;
          background-size: 100% 100%;
        }
      }
    }
  }
  .interfaceArea,
  .interfaceTotal {
    height: 560px;
    background: #ffff;
    box-shadow: 0px 0px 32px 0px rgba(66, 114, 153, 0.1);
    padding: 20px;
    box-sizing: border-box;
    margin: 0 0 20px 20px;
    #interfacePie {
      height: 480px;
    }
    #interfaceLine {
      height: 480px;
    }
  }
  @media screen and(max-width:1600px) {
    .resourceRank {
      .item {
        .item-l,
        .item-r {
          font-size: 14px !important;
        }
      }
    }
  }
}
</style>
